import React from 'react';

import CompetencesContainer, {
  COMPETENCES,
  genererFilAriane,
} from './container';

const Mediation = () => {
  return (
    <CompetencesContainer currentSection={COMPETENCES.MEDIATION}>
      <h1>Médiation</h1>

      {genererFilAriane('médiation')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="mediation"
          src="/img/competences_sprite.jpg"
          alt="Médiation"
        />
      </div>

      <p>
        La médiation familiale peut vous aider à négocier une{' '}
        <span className="accent">entente à l’amiable</span>, dans l’intérêt de
        tous les membres de la famille et à réduire vos frais juridiques par le
        biais d’un nombre déterminé de séances subventionnées par le
        Gouvernement du Québec.
      </p>
      <p>
        Le recours à un{' '}
        <span className="accent2">médiateur indépendant et qualifié</span>{' '}
        permet aux parties de négocier à force égale la mise en place d’une
        entente post-rupture respectant les besoins distincts de chacune
        d’elles.
      </p>
      <p>
        Les parties déjà séparées ou divorcées qui veulent apporter un
        changement à leur entente ou jugement peuvent également y arriver dans
        le cadre de <span className="accent2">négociations</span> en médiation
        familiale.
      </p>
    </CompetencesContainer>
  );
};

Mediation.propTypes = {};

export default Mediation;
