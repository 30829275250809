import React from 'react';

import Mediation from '../../components/fr/competences/mediation';
import { PAGES } from '../../utils/utils';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const MediationPage = () => (
  <Layout page={PAGES.COMPETENCES_MEDIATION}>
    <SEO title="Médiation" />
    <Mediation />
  </Layout>
);

export default MediationPage;
